<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <!-- search input -->
    <section class="tw-mb-6 text-center">
      <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
        <h2 class="tw-font-bold tw-text-gray-600">Knowledge Base</h2>
        <p class="tw-mb-6 tw-text-gray-500">
          <span>Here you will find all the information you need.</span>
        </p>

        <input
          id="searchbar"
          v-model="knowledgeBaseSearchQuery"
          class="tw-text-sm tw-bg-gray-50 tw-px-4 tw-py-3 tw-border tw-border-gray-200 tw-rounded-lg tw-placeholder-gray-400 tw-w-full md:tw-w-[500px] tw-outline-gray-800 tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-100"
          placeholder="What do you want to know?"
        />

        <div class="tw-mt-4 tw-flex tw-gap-2 tw-justify-center tw-flex-col md:tw-flex-row">
          <a
            v-if="$can('edit', 'Knowledge-base')"
            class="tw-bg-gray-100 tw-text-gray-600 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-gray-200 tw-border tw-border-gray-100 hover:tw-border-gray-300 tw-transition"
            @click="showModal = true"
          >
            Create new group
          </a>
          <a
            v-if="$can('edit', 'Knowledge-base')"
            :href="$router.resolve({name: 'knowledge-base-create'}).href"
            class="tw-bg-gray-100 tw-text-gray-600 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-gray-200 tw-border tw-border-gray-100 hover:tw-border-gray-300 tw-transition"
          >
            Create new article
          </a>
        </div>
      </div>
    </section>
    <!--/ search input -->

    <div
      class="tw-flex-grow tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-6 tw-h-full tw-overflow-auto board-scroll"
    >
      <div v-for="item in filteredGroups" :key="item.id" class="kb-search-content">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <div class="mb-50 d-flex justify-content-between">
            <div class="tw-text-lg tw-font-medium tw-flex tw-items-center">
              <feather-icon :class="item.icon_color" :icon="item.icon" class="mr-50" size="20" />
              {{ item.title }} ({{ item['publications'].length }})
            </div>
            <div v-if="$can('edit', 'Knowledge-base')" class="tw-flex tw-h-5">
              <svg
                v-tippy="{content: 'Edit'}"
                class="tw-ml-1.5 tw-cursor-pointer tw-transition hover:tw-text-sky-700/90"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                @click="
                  () => {
                    getGroup(item.id);
                    editGroup = true;
                    showModal = true;
                  }
                "
              >
                <path
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                v-tippy="{content: 'Delete'}"
                class="tw-ml-1.5 tw-cursor-pointer tw-transition hover:tw-text-red-600/90"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                @click="deleteGroup(item.id)"
              >
                <path
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <ul class="tw-mt-4 tw-mb-0">
            <li class="tw-w-full">
              <a
                v-for="article in item['publications']"
                :key="article.id"
                :href="$router.resolve({name: 'knowledge-base-publication', params: {slug: article.id}}).href"
                class="tw-inline-block tw-text-gray-500 tw-px-3 tw-py-1 tw-transition tw-border tw-border-white hover:tw-bg-gray-50 hover:tw-border hover:tw-border-gray-100 tw-w-full tw-rounded-md"
              >
                {{ article.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="loadingGroups" class="w-100 text-center">
        <os-loader />
      </div>

      <!-- no result found -->
      <b-col v-show="!filteredGroups.length && !loadingGroups" class="text-center" cols="12">
        <h4 class="mt-4">Search result not found!</h4>
      </b-col>
      <!--/ no result found -->
    </div>

    <b-modal
      id="modal-primary"
      v-model="showModal"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Accept')"
      centered
      content-class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl"
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
    >
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0">{{ editGroup ? $t('Editing a group') : $t('Creating a group') }}</h4>
        <feather-icon class="cursor-pointer" icon="XIcon" @click="showModal = false" />
      </div>
      <hr class="mt-75" />
      <b-form-group label="Title">
        <b-form-input v-model="newGroup.title" placeholder="Enter title" />
      </b-form-group>
      <!--      <b-form-group :label="$t('Category')" class="mb-0">-->
      <!--        <b-form-select v-model="newGroup.category" :placeholder="$t('Select category')" />-->
      <!--      </b-form-group>-->
      <b-form-group label="Icon">
        <v-select
          v-model="newGroup.icon"
          :clearable="false"
          :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
          :options="icons"
          label="title"
        >
          <template #option="{title}">
            <div class="tw-flex tw-items-center">
              <feather-icon :icon="title" class="align-middle mr-50" size="16" />
              <span> {{ title }}</span>
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Icon color">
        <v-select
          :value="newGroup.icon_color.replace('text-', '')"
          :clearable="false"
          :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
          :options="colors"
          label="title"
          @option:selecting="newGroup.icon_color = $event"
        >
          <template #option="{title}">
            <div class="tw-flex tw-items-center tw-gap-x-2">
              <span :class="title.replace('text', 'bg')" class="tw-rounded-full tw-w-2 tw-h-2"></span>
              <span>{{ title.replace('text-', '') }}</span>
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-button
        block
        class="mt-2 tw-py-4 rounded-nrm"
        variant="primary"
        @click="editGroup ? updateGroup() : createGroup()"
      >
        {{ editGroup ? $t('Update') : $t('Create') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput} from 'bootstrap-vue';
import WikiService from '@/services/wiki.service';
import vSelect from 'vue-select';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      showModal: false,
      newGroup: {
        category: null,
        icon: 'ActivityIcon',
        icon_color: 'text-primary',
        title: '',
      },
      editGroup: false,
      loadingGroups: false,
      groups: [],
      colors: [
        'text-primary',
        'text-success',
        'text-danger',
        'text-warning',
        'text-info',
        'text-dark',
        'text-secondary',
        'text-light',
      ],
      icons: [
        'ActivityIcon',
        'AirplayIcon',
        'AlertCircleIcon',
        'AlertOctagonIcon',
        'AlertTriangleIcon',
        'AlignCenterIcon',
        'AlignJustifyIcon',
        'AlignLeftIcon',
        'AlignRightIcon',
        'AnchorIcon',
        'ApertureIcon',
        'ArchiveIcon',
        'ArrowDownCircleIcon',
        'ArrowDownLeftIcon',
        'ArrowDownRightIcon',
        'ArrowDownIcon',
        'ArrowLeftCircleIcon',
        'ArrowLeftIcon',
        'ArrowRightCircleIcon',
        'ArrowRightIcon',
        'ArrowUpCircleIcon',
        'ArrowUpLeftIcon',
        'ArrowUpRightIcon',
        'ArrowUpIcon',
        'AtSignIcon',
        'AwardIcon',
        'BarChart2Icon',
        'BarChartIcon',
        'BatteryChargingIcon',
        'BatteryIcon',
        'BellOffIcon',
        'BellIcon',
        'BluetoothIcon',
        'BoldIcon',
        'BookOpenIcon',
        'BookIcon',
        'BookmarkIcon',
        'BoxIcon',
        'BriefcaseIcon',
        'CalendarIcon',
        'CameraOffIcon',
        'CameraIcon',
        'CastIcon',
        'CheckCircleIcon',
        'CheckSquareIcon',
        'CheckIcon',
        'ChevronDownIcon',
        'ChevronLeftIcon',
        'ChevronRightIcon',
        'ChevronUpIcon',
        'ChevronsDownIcon',
        'ChevronsLeftIcon',
        'ChevronsRightIcon',
        'ChevronsUpIcon',
        'ChromeIcon',
        'CircleIcon',
        'ClipboardIcon',
        'ClockIcon',
        'CloudDrizzleIcon',
        'CloudLightningIcon',
        'CloudOffIcon',
        'CloudRainIcon',
        'CloudSnowIcon',
        'CloudIcon',
        'CodeIcon',
        'CodepenIcon',
        'CodesandboxIcon',
        'CoffeeIcon',
        'ColumnsIcon',
        'CommandIcon',
        'CompassIcon',
        'CopyIcon',
        'CornerDownLeftIcon',
        'CornerDownRightIcon',
        'CornerLeftDownIcon',
        'CornerLeftUpIcon',
        'CornerRightDownIcon',
        'CornerRightUpIcon',
        'CornerUpLeftIcon',
        'CornerUpRightIcon',
        'CpuIcon',
        'CreditCardIcon',
        'CropIcon',
        'CrosshairIcon',
        'DatabaseIcon',
        'DeleteIcon',
        'DiscIcon',
        'DivideCircleIcon',
        'DivideSquareIcon',
        'DivideIcon',
        'DollarSignIcon',
        'DownloadCloudIcon',
        'DownloadIcon',
        'DribbbleIcon',
        'DropletIcon',
        'Edit2Icon',
        'Edit3Icon',
        'EditIcon',
        'ExternalLinkIcon',
        'EyeOffIcon',
        'EyeIcon',
        'FacebookIcon',
        'FastForwardIcon',
        'FeatherIcon',
        'FigmaIcon',
        'FileMinusIcon',
        'FilePlusIcon',
        'FileTextIcon',
        'FileIcon',
        'FilmIcon',
        'FilterIcon',
        'FlagIcon',
        'FolderMinusIcon',
        'FolderPlusIcon',
        'FolderIcon',
        'FramerIcon',
        'FrownIcon',
        'GiftIcon',
        'GitBranchIcon',
        'GitCommitIcon',
        'GitMergeIcon',
        'GitPullRequestIcon',
        'GithubIcon',
        'GitlabIcon',
        'GlobeIcon',
        'GridIcon',
        'HardDriveIcon',
        'HashIcon',
        'HeadphonesIcon',
        'HeartIcon',
        'HelpCircleIcon',
        'HexagonIcon',
        'HomeIcon',
        'ImageIcon',
        'InboxIcon',
        'InfoIcon',
        'InstagramIcon',
        'ItalicIcon',
        'KeyIcon',
        'LayersIcon',
        'LayoutIcon',
        'LifeBuoyIcon',
        'Link2Icon',
        'LinkIcon',
        'LinkedinIcon',
        'ListIcon',
        'LoaderIcon',
        'LockIcon',
        'LogInIcon',
        'LogOutIcon',
        'MailIcon',
        'MapPinIcon',
        'MapIcon',
        'Maximize2Icon',
        'MaximizeIcon',
        'MehIcon',
        'MenuIcon',
        'MessageCircleIcon',
        'MessageSquareIcon',
        'MicOffIcon',
        'MicIcon',
        'Minimize2Icon',
        'MinimizeIcon',
        'MinusCircleIcon',
        'MinusSquareIcon',
        'MinusIcon',
        'MonitorIcon',
        'MoonIcon',
        'MoreHorizontalIcon',
        'MoreVerticalIcon',
        'MousePointerIcon',
        'MoveIcon',
        'MusicIcon',
        'Navigation2Icon',
        'NavigationIcon',
        'OctagonIcon',
        'PackageIcon',
        'PaperclipIcon',
        'PauseCircleIcon',
        'PauseIcon',
        'PenToolIcon',
        'PercentIcon',
        'PhoneCallIcon',
        'PhoneForwardedIcon',
        'PhoneIncomingIcon',
        'PhoneMissedIcon',
        'PhoneOffIcon',
        'PhoneOutgoingIcon',
        'PhoneIcon',
        'PieChartIcon',
        'PlayCircleIcon',
        'PlayIcon',
        'PlusCircleIcon',
        'PlusSquareIcon',
        'PlusIcon',
        'PocketIcon',
        'PowerIcon',
        'PrinterIcon',
        'RadioIcon',
        'RefreshCcwIcon',
        'RefreshCwIcon',
        'RepeatIcon',
        'RewindIcon',
        'RotateCcwIcon',
        'RotateCwIcon',
        'RssIcon',
        'SaveIcon',
        'ScissorsIcon',
        'SearchIcon',
        'SendIcon',
        'ServerIcon',
        'SettingsIcon',
        'Share2Icon',
        'ShareIcon',
        'ShieldOffIcon',
        'ShieldIcon',
        'ShoppingBagIcon',
        'ShoppingCartIcon',
        'ShuffleIcon',
        'SidebarIcon',
        'SkipBackIcon',
        'SkipForwardIcon',
        'SlackIcon',
        'SlashIcon',
        'SlidersIcon',
        'SmartphoneIcon',
        'SmileIcon',
        'SpeakerIcon',
        'SquareIcon',
        'StarIcon',
        'StopCircleIcon',
        'SunIcon',
        'SunriseIcon',
        'SunsetIcon',
        'TabletIcon',
        'TagIcon',
        'TargetIcon',
        'TerminalIcon',
        'ThermometerIcon',
        'ThumbsDownIcon',
        'ThumbsUpIcon',
        'ToggleLeftIcon',
        'ToggleRightIcon',
        'ToolIcon',
        'Trash2Icon',
        'TrashIcon',
        'TrelloIcon',
        'TrendingDownIcon',
        'TrendingUpIcon',
        'TriangleIcon',
        'TruckIcon',
        'TvIcon',
        'TwitchIcon',
        'TwitterIcon',
        'TypeIcon',
        'UmbrellaIcon',
        'UnderlineIcon',
        'UnlockIcon',
        'UploadCloudIcon',
        'UploadIcon',
        'UserCheckIcon',
        'UserMinusIcon',
        'UserPlusIcon',
        'UserXIcon',
        'UserIcon',
        'UsersIcon',
        'VideoOffIcon',
        'VideoIcon',
        'VoicemailIcon',
        'Volume1Icon',
        'Volume2Icon',
        'VolumeXIcon',
        'VolumeIcon',
        'WatchIcon',
        'WifiOffIcon',
        'WifiIcon',
        'WindIcon',
        'XCircleIcon',
        'XOctagonIcon',
        'XSquareIcon',
        'XIcon',
        'YoutubeIcon',
        'ZapOffIcon',
        'ZapIcon',
        'ZoomInIcon',
        'ZoomOutIcon',
      ],
    };
  },
  mounted() {
    this.getGroups();
  },
  computed: {
    filteredGroups() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase();
      return this.groups.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower));
    },
  },
  methods: {
    getGroups() {
      this.loadingGroups = true;
      WikiService.getGroups().then(r => {
        this.groups = r.data;
        this.loadingGroups = false;
      });
    },
    getGroup(id) {
      WikiService.getGroup(id).then(r => {
        this.newGroup = r.data;
      });
    },
    createGroup() {
      WikiService.createGroup(this.newGroup).then(() => this.getGroups());
      this.showModal = false;
      this.newGroup.category = null;
      this.newGroup.icon = '';
      this.newGroup.title = '';
    },
    updateGroup() {
      WikiService.updateGroup(this.newGroup).then(() => this.getGroups());
      this.showModal = false;
      this.editGroup = false;
      this.newGroup.category = null;
      this.newGroup.icon = '';
      this.newGroup.title = '';
    },
    deleteGroup(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          size: 'sm',
        })
        .then(value => {
          if (value) {
            this.loadingGroups = true;
            WikiService.deleteGroup(id).then(() => {
              this.getGroups();
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-knowledge-base.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.knowledge-base-bg {
  background-color: #fff !important;
}

.list-group-item {
  padding: 0.45rem 1.25rem;

  &::after {
    top: 0.85rem !important;
  }
}
</style>
